<template>
  <Editor ref="editor" :initialValue="contents"
          :options="editorOptions"
          height="400px"
          initialEditType="wysiwyg"
          previewStyle="vertical" />
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from "sweetalert2";
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import '@toast-ui/editor/dist/i18n/ko-kr';
import '@toast-ui/editor/dist/i18n/ja-jp';

export default {
  components: { Editor },
  props: {
    contents: String,
  },
  mounted() {
    if (this.contents) {
      this.$refs.editor.invoke('setHTML', this.contents);
    }
  },
  data() {
    return {
      editorOptions: {
        //language: 'ja',
        language: 'ko',
        hideModeSwitch: false,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol'],
          ['table', 'link', 'image'],
          ['code', 'codeblock'],
          ['scrollSync'],
        ],
        usageStatistics: false,
        hooks: {
          addImageBlobHook: this.addImageBlobHook
        }
      },
    }
  },
  methods: {
    getHTML() {
      return this.$refs.editor.invoke('getHTML');
    },
    addImageBlobHook(file, setText, type) {
      console.log('FILE')
      try {
        if (!file) return false;

        const formData = new FormData();
        formData.append("file", file);

        const _url = '/onmapi/view/File/create';

        http.post(_url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.status === 201) {
            Swal.fire({
              icon: 'info',
              title: `이미지를 ${i18n.t('alert.created')}`,
              confirmButtonText: i18n.t('btn.close')
            });
            setText(`${res.data.fileInfo.filePath}`, 'image');
          }
        }).catch(err => {
          console.error('File Create Error :: ', err)
        });
      } catch (err) {
        console.log(err)
      }
    },
  }
}
</script>
